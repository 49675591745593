import React, { useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { zipWith } from 'lodash';
import css from './SectionAdditionallOrdersInfoMaybe.module.css';
import { Modal, ModalInMobile, ModalInviteFriend } from '../index';
import config from '../../config';
import { getFormattedByDayAndTimeCustomPeriods } from './helper';

const SectionAdditionallOrdersInfoMaybe = props => {
  const {
    listing,
    className,
    onManageDisableScrolling,
    isTeacher,
    isCourse,
    isJob,
    customPeriods,
  } = props;

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const handleScheduleModalClose = () => {
    setIsScheduleModalOpen(false);
  };

  const { publicData } = listing.attributes;
  const daysArray = publicData?.daysArray;
  const previewPeriod = config.custom.periodOptions.filter(objFromA => (publicData?.period?.find(objFromB => (objFromA.key === objFromB))));
  const weeks = publicData?.weeks;
  const seats = publicData?.seats;
  const estimatedHours = publicData?.estimatedHours;

  const daysArrayComponent = daysArray?.map(day => {
    return (
      <li
        className={day.holiday === true ? classNames(css.daysItem, css.daysItemHoliday) : css.daysItem}>
        <span className={classNames(css.daysSpan, css.daysSpan1)}>{day.dayOfWeek}</span>
        <span className={classNames(css.daysSpan, css.daysSpan2)}>{day.day}</span>
        <span className={classNames(css.daysSpan, css.daysSpan3)}>
          <span>{day.startTime}</span>
          <span> - </span>
          <span>{day.endTime}</span>
        </span>
        <span className={classNames(css.daysSpan, css.daysSpan4)}>{day.timeZone}</span>
      </li>
    );
  });

  const scheduleType = publicData && publicData.scheduleType;
  const isScheduleCourse = scheduleType && scheduleType.key === 'schedule_a_course';
  const isContactToSchedule = scheduleType && scheduleType.key === 'contact_to_schedule';
  const frequency = publicData && publicData.frequency && publicData.frequency.value;
  const timeZone = publicData && publicData.frequency && publicData.timeZone.value;
  const daysOfWeekArray = publicData && publicData.daysOfWeek;
  const daysOfWeek = daysOfWeekArray && daysOfWeekArray.map(item => <span key={item}
                                                                          className={css.itemSpan}>{item}</span>);
  const dates = publicData && publicData.dates;
  const startDate = dates && moment(dates.startDate).format('MM/DD/YYYY');
  const endDate = dates && moment(dates.endDate).format('MM/DD/YYYY');
  const startTime = publicData && publicData.startTime && publicData.startTime.value;
  const startTimeNumber = publicData && publicData.startTime && parseInt(publicData.startTime.key.replace(':00', ''));
  const endTime = publicData && publicData.endTime && publicData.endTime.value;
  const endTimeNumber = publicData && publicData.endTime && parseInt(publicData.endTime.key.replace(':00', ''));
  const duration = endTimeNumber - startTimeNumber;
  const classDuration = publicData && publicData && publicData.duration;
  const numberOfClasses = publicData && publicData.numberOfClasses;
  const formattedCustomPeriods = customPeriods && getFormattedByDayAndTimeCustomPeriods(customPeriods);

  const renderCustomPeriod = formattedCustomPeriods?.map(item => {
    return Object.entries(item).map(([key, value], i) => {
      return (
        <li className={css.customPeriodsItem} key={i}>
          <span className={css.customPeriodsDay}>{key}: </span>
          <span className={css.customPeriodsTime}>
            {value.map(v => <span className={css.itemSpan}>{v.startTime} - {v.endTime}</span>)}
          </span>

        </li>
      );
    });
  });

  const classes = classNames(className || css.additionalIOrdersInfo);

  return (
    <div className={classes}>
      {isContactToSchedule && <ul className={classNames(css.list, css.listBig)}>
        {classDuration && <li className={css.item}>Duration of class: <span
          className={css.subItem}>{classDuration}</span></li>}
        {numberOfClasses && <li className={css.item}>Total number of classes: <span
          className={css.subItem}>{numberOfClasses}</span></li>}
      </ul>}

      {isScheduleCourse && <ul className={classNames(css.list, css.listBig)}>
        {frequency && <li className={classNames(css.item, css.itemBig)}>Frequency: <span
          className={css.subItem}>{frequency}</span></li>}
        {daysOfWeek && <li className={classNames(css.item, css.itemBig)}>Days of the week: <span
          className={classNames(css.subItem, css.subItemCup)}>{daysOfWeek}</span></li>}
        {startDate && endDate &&
          <li className={classNames(css.item, css.itemBig)}>Start date through End Date date:
            <span className={css.subItem}> {startDate}</span>
            <span className={css.subItem}> - </span>
            <span className={css.subItem}>{endDate}</span>
          </li>}
        {startTime && endTime &&
          <li className={classNames(css.item, css.itemBig)}>Start time and end time:
            <span className={css.subItem}> {startTime} {timeZone}</span>
            <span className={css.subItem}> through </span>
            <span className={css.subItem}>{endTime} {timeZone}</span>
          </li>}
        {duration && <li className={classNames(css.item, css.itemBig)}>Duration: <span
          className={css.subItem}>{duration} h</span></li>}

        {!isTeacher && !isJob && <li className={classNames(css.item, css.itemBig)}
                                     onClick={() => setIsScheduleModalOpen(true)}>Schedule: <span
          className={classNames(css.subItem, css.subItemLink)}>See more...</span></li>}
      </ul>}

      {!isCourse && <ul className={classNames(css.listJobs, css.listBig)}>
        {weeks && <li className={classNames(css.item, css.itemBig)}>Days a week: <span
          className={css.subItem}>{weeks}</span></li>}
        {previewPeriod && previewPeriod[0] &&
          <li className={classNames(css.item, css.itemBig)}>Period: <span
            className={css.subItem}>{previewPeriod[0].label}</span></li>}
        {estimatedHours &&
          <li className={classNames(css.item, css.itemBig)}>Estimated number of hours: <span
            className={css.subItem}>{estimatedHours}</span></li>}
        {!isCourse && customPeriods?.length > 0 && <li className={css.item}>Schedule:
          <span className={css.subItem}>
            <ul className={css.customPeriodsList}>
              {renderCustomPeriod}
            </ul>
          </span>
        </li>}
        {/*{seats && !isCourse && <li className={classNames(css.item, css.itemBig, css.itemMobile)}>Number of seats: <span className={css.subItem}>{seats}</span></li>}*/}

      </ul>}

      {onManageDisableScrolling && <Modal
        id='ScheduleModal'
        isOpen={isScheduleModalOpen}
        onClose={handleScheduleModalClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={' '}
      >
        <h1 className={css.modalTitle}>Schedule</h1>
        <ul className={css.tableHeaderList}>
          <li className={classNames(css.tableHeaderItem, css.tableHeaderItem1)}>Day</li>
          <li className={classNames(css.tableHeaderItem, css.tableHeaderItem2)}>Date</li>
          <li className={classNames(css.tableHeaderItem, css.tableHeaderItem3)}>Time</li>
          <li className={classNames(css.tableHeaderItem, css.tableHeaderItem4)}>TZ</li>
        </ul>
        <ul className={css.daysList}>
          {daysArrayComponent}
        </ul>

      </Modal>}

    </div>
  );
};

export default SectionAdditionallOrdersInfoMaybe;
