import React from 'react';

import css from './TransactionPanel.module.css';
import config from "../../../config";

// Functional component as a helper to build detail card headings
const JobInfoMaybe = props => {
  const { listing } = props;

  const { publicData, title, description } = listing.attributes;
  const {  license, maxPrice, requiredMaterials, seats, estimatedHours, weeks } = publicData;
  const previewPeriod = config.custom.periodOptions.filter(objFromA => (publicData?.period?.find(objFromB => (objFromA.key === objFromB))));

  const renderWeeks = weeks?.map(item => <span>{item}</span>);
  const renderPeriod = previewPeriod?.map(item => <span>{item.value}</span>);

  return (
    <>
      <div className={css.contentSection}>
        {maxPrice && <div className={css.contentLine}>
          <span className={css.contentLineTitle}>Maximum hourly rate: </span>
          <span className={css.contentLineData}>${maxPrice / 100}</span>
        </div>}
        {seats && <div className={css.contentLine}>
          <span className={css.contentLineTitle}>Number of seats: </span>
          <span className={css.contentLineData}>{seats}</span>
        </div>}
      </div>

      <div className={css.contentSection}>
        <h2 className={css.subTitle}>Schedule:</h2>
        {renderWeeks && <div className={css.contentLine}>
          <span className={css.contentLineTitle}>Days a week: </span>
          <span className={css.contentLineData}>{renderWeeks}</span>
        </div>}
        {renderPeriod && <div className={css.contentLine}>
          <span className={css.contentLineTitle}>Period: </span>
          <span className={css.contentLineData}>{renderPeriod}</span>
        </div>}
        {estimatedHours && <div className={css.contentLine}>
          <span className={css.contentLineTitle}>Estimated number of hours: </span>
          <span className={css.contentLineData}>{estimatedHours}</span>
        </div>}

      </div>
    </>

)
};

export default JobInfoMaybe;
